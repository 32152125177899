import _ from 'lodash';

export function getBaseUrl(): string {
  // example: https://test-app.thehost.com
  return window.location.origin;
}

export function getSubdomainUrl(subdomain: string): string {
  return `https://${subdomain}.thehost.co`;
}

interface getStoreLinkProps {
  isEnabled: boolean;
  subdomain: string;
  isSubdomainEnabled: boolean;
  storeId: number;
}

export const getStoreLink = ({
  isEnabled,
  subdomain,
  isSubdomainEnabled,
  storeId,
}: getStoreLinkProps): string => {
  if (!isEnabled || !subdomain || !isSubdomainEnabled) {
    return `${getBaseUrl()}/${storeId}/`;
  }
  return `${getSubdomainUrl(subdomain)}/${storeId}/`;
};

export const convertPxToRem = ({ px: number }): string => {
  return `${number / 16}rem`;
};

/**
 * Call this function as a placeholder whenever there's a point in the code that should not be reached.
 *
 * The Type is set to () => void so it doesn't clash with overriding implementations
 *
 * Example usage: when defining the types for a Context provider.
 *
 */
export const notImplementedError = (): void => {
  throw new Error('Not Implemented.');
};

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

export const stringConvertToBoolean = ({ value }: { value: string | boolean }) =>
  value === true ||
  (_.isString(value) && value.toLowerCase() === 'true') ||
  (_.isString(value) && value.toLowerCase() === 'yes') ||
  value === '1';
