import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { get, put, post, patch } from 'api/api';
import { getBaseUrl } from 'utils/utils';
import { formatDateToQueryParams } from '../utils/datetime';

const getActiveStores = (stores) => stores?.filter((item) => item.isActive === true);

export function getOwnerDetails(itemId) {
  const url = `/services/owner/${itemId}/detail/`;
  return get(url, {});
}

export function useOwnerDetails(itemId) {
  const { mutate } = useSWRConfig();
  const key = `/services/owner/${itemId}/detail/`;
  const { data, error } = useSWR(key, get);

  return {
    data: {
      ...data?.data,
      stores: getActiveStores(data?.data?.stores),
    },
    invalidate: () => {
      mutate(key);
    },
    isLoading: !error && !data,
    isError: error,
  };
}
export async function getOwnerPayouts(params) {
  const url = `/services/owner/payouts/`;
  const startDate = formatDateToQueryParams(params.startDate);
  const endDate = formatDateToQueryParams(params.endDate);

  const response = await get(url, null, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return response;
}

export function useOwnerPayouts(params) {
  const memoizedParams = useMemo(() => params, [params]);
  const { mutate } = useSWRConfig();
  const url = `/services/owner/payouts/`;
  const { data, error } = useSWR([url, memoizedParams], () => getOwnerPayouts(params));

  return {
    data: data?.data,
    invalidate: () => {
      mutate([url, memoizedParams]);
    },
    isLoading: !error && !data,
    isError: error,
  };
}

const getShopById = (storeId, data) => data?.stores?.find(({ id }) => id === storeId);

export function useOwnerStore(ownerId, storeId) {
  const { data, isLoading, invalidate } = useOwnerDetails(ownerId);
  const storeData = getShopById(storeId, data);

  return { data: storeData, isLoading, invalidate };
}

export function updateOwner(values) {
  const url = `/services/owner/${values.id}/`;
  return patch(url, values);
}

export const updateActor = (payload) => {
  return patch(`/services/actor/${payload.id}/`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export function changePassword(params) {
  const url = '/services/user/current/passwordchange/';
  return put(url, params);
}

export function closeTutorial(ownerId) {
  const url = `/services/owner/${ownerId}/`;
  return patch(url, { hasClosedTutorial: true }, { f2bDict: ownerF2BDict });
}

const ownerF2BDict = {
  actor: {
    firstName: 'first_name',
    lastName: 'last_name',
  },
  hasClosedTutorial: 'has_closed_tutorial',
};

export function generateToken({ email }) {
  const url = '/services/password-reset/gen-token/';
  return post(url, {
    url: `${getBaseUrl()}/reset-password/`,
    email,
  });
}

export function useToken({ password, token }) {
  const url = '/services/password-reset/use-token/';
  return post(url, { password, token });
}

export function getStoreList(ownerId) {
  const { mutate } = useSWRConfig();
  const url = `/services/owner/${ownerId}/stores/`;
  const { data, error } = useSWR(url, get);
  return {
    data: {
      ...data?.data,
      stores: getActiveStores(data?.data?.stores),
    },
    invalidate: (newData, forceRequest) => {
      mutate(url, newData, forceRequest);
    },
    isLoading: !error && !data,
    isError: error,
  };
}

export function getPromoCodeValidity(promoCode) {
  const url = `/services/promocode/apply/${promoCode}/`;
  return get(url, {});
}

export function ownerSetLocalProducts(payload) {
  const url = `/services/owner/set-local-products/`;
  return post(url, payload);
}

export const ownerPhoneNotificationsSettingsUpdate = (payload) => {
  const url = `/services/owner/phone-notification-settings/update/`;
  return post(url, payload);
};

export const useOwnerPhoneNotificationSettings = () => {
  const key = `/services/owner/phone-notification-settings/retrieve/`;
  const { data, error, mutate } = useSWR(key, () => get(key));

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error || !data?.ok,
    mutate,
  };
};
