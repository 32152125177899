import React, { useCallback, useMemo, useState } from 'react';

import UnauthLayout from 'components/UnauthLayout';
import { Row, Col, Divider, Form } from 'antd';
import { Button, Checkbox, InputRules, Link, Modal, TextFieldPassword, Typography } from 'ui-kit';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { coHostSetupPassword, useCoHostCheckUUID } from 'api/co-hosts';
import { useParams } from 'react-router-dom';
import Alert from 'components/Alert';
import { setPasswordValidSchema } from './utils';
import './styles.scss';

interface InitialValues {
  password: string;
  confirmPassword: string;
}

const defaultValues: InitialValues = {
  password: '',
  confirmPassword: '',
};

const CoHostSetPasswordPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const { invitationUUID } = useParams<{ invitationUUID: string }>();

  const { data } = useCoHostCheckUUID({ uuid: invitationUUID });

  const showUUIDError = useMemo(() => !data?.isValid, [data]);

  const { control, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: setPasswordValidSchema,
  });

  const onSubmit = () => {
    setModalIsOpen(true);
  };

  const onConfirm = useCallback(() => {
    setLoading(true);
    const data = getValues();

    coHostSetupPassword({ uuid: invitationUUID, password: data.password }).then((res) => {
      if (res.ok) {
        setShowSuccess(true);
        setModalIsOpen(false);
        setLoading(false);
      }
    });
  }, [getValues, invitationUUID]);

  return (
    <>
      <UnauthLayout>
        <Row gutter={[0, 16]}>
          <Col xs={24}>
            <div>
              <Typography variant="h6">You are invited as a co-host user. </Typography>
              <Divider />
            </div>
          </Col>
          {showUUIDError && (
            <Col xs={24}>
              <Alert type="error">This invitation is invalid.</Alert>
            </Col>
          )}
          {showSuccess && (
            <>
              <Col xs={24}>
                <Typography variant="body2">The password was set up successfully.</Typography>
              </Col>
              <Col xs={24}>
                <Button to="/login" variant="contained" fullWidth>
                  Sign in
                </Button>
              </Col>
            </>
          )}
          {!showSuccess && !showUUIDError && (
            <>
              <Col xs={24}>
                <Typography variant="body2">You should set up your password.</Typography>
              </Col>
              <Col xs={24}>
                <Form name="test" layout="vertical">
                  <Row gutter={[0, 16]}>
                    <Col xs={24}>
                      <Controller
                        control={control}
                        name="password"
                        render={({
                          field: { ref, value, name, onChange },
                          fieldState: { error },
                        }) => {
                          const errors = ((error ?? []) as Array<FieldError>).map(
                            ({ message }) => message,
                          );

                          function isValid(key) {
                            if (!errors.length) {
                              return Boolean(value);
                            }

                            return !errors.includes(key);
                          }

                          return (
                            <>
                              <TextFieldPassword
                                inputRef={ref}
                                label="New Password"
                                name={name}
                                onChange={onChange}
                                required
                                fullWidth
                                value={value}
                              />
                              <InputRules
                                label="Password must contain:"
                                rules={[
                                  {
                                    message: 'At least 8 characters',
                                    isValid: isValid('min-length'),
                                  },
                                  {
                                    message: 'One uppercase letter',
                                    isValid: isValid('uppercase'),
                                  },
                                  { message: 'One number', isValid: isValid('one-digit') },
                                  {
                                    message: 'One special character',
                                    isValid: isValid('special-character'),
                                  },
                                ]}
                              />
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={24}>
                      <Controller
                        control={control}
                        name="confirmPassword"
                        render={({ field: { name, onChange }, fieldState: { error } }) => (
                          <TextFieldPassword
                            label="Confirm Password"
                            name={name}
                            onChange={onChange}
                            required
                            fullWidth
                            error={!!error}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </Col>
                    <Col xs={24}>
                      <Form.Item noStyle shouldUpdate>
                        {() => (
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={(data) => handleSubmit(onSubmit)(data)}
                          >
                            Save
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
      </UnauthLayout>
      <Modal
        className="SignupPage-agreement-modal"
        visible={modalIsOpen}
        title="Complete signing up"
        onCancel={() => {
          setModalIsOpen(false);
        }}
        footer={
          <Button
            variant="contained"
            onClick={onConfirm}
            fullWidth
            disabled={!checked}
            loading={loading}
          >
            Confirm
          </Button>
        }
      >
        <div className="SignupPage-agreement-modal__content">
          <Checkbox
            className="agreement-checkbox"
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          />
          <p>
            By continuing, you agree to The Host Co.&nbsp;
            <Link href="https://www.thehost.co/terms-conditions">terms of service</Link>
            &nbsp;and&nbsp;
            <Link href="https://www.thehost.co/privacy-policy">privacy policy</Link>.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default CoHostSetPasswordPage;
